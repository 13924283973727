

/* #presentation_titre{
    display: inline-block;
    height:10px;
    width:100%;
    color:white;
    text-shadow: black 0.1em 0.1em 0.2em,-1px 0 black, 0 1px black,
    1px 0 black, 0 -1px black;
    font-size: 3.2em;
    text-align: center;
  }

#presentation_slogan{
    color: white;
    text-shadow: black 0.1em 0.1em 0.2em,-1px 0 black, 0 1px black,
    1px 0 black, 0 -1px black;
    font-size: 2.5em;
    text-align: center;
} */

#presentation_titre{
  text-align: center;
}

div.fullcenter{
  font-size: 2.5em;
  text-align: center;
  position: relative;
  max-width: 100vw;
  margin: auto auto;
}

div.fullcenter h1{
  line-height: unset;
  height: unset;
  font-size : 2em;
  padding: 10px;
  margin: 10px 0;
  padding-bottom: 0;
  font-family: "Earth";
}
div.fullcenter h1 span.little{
  font-size: .7em;
  font-family: "Earth";
}

div.fullcenter #presentation_slogan{
  font-size : 1em;
  margin: 0;
}

div.fullcenter img#logo{
  height: 6.5rem;
  width: auto;
}

#button_welcome_propose button{
  margin-top: 30px;
  font-size : 2rem;
  height: unset;
  width: auto;
  cursor: pointer;
  background-color: #223686;
  border-radius: 5px;
  transition: .3s;
  padding: 10px;
  color: #fff;
  display: flex;
  align-items: center;
}

#carrousel {
  display: flex;
  margin: 0;
  margin-top: 40px;
  max-width: 70vw;
  overflow-x: scroll;
  padding: 0;
  scroll-snap-type: x mandatory;
  font-size: 1rem;
}

.c_items {
  padding: 20px 30px;
  scroll-snap-align: center;
}
  

#button_welcome_propose button:hover {
  background-color: #304bb8;
}

@media (max-width: 800px){
  #carrousel {
    max-width: 95vw;
  }
}