.listusers {
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin: 5px;
  padding: 5px;
  gap: 5px;
  width: 60%;
  margin:auto;
  }