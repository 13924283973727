.car {
    width: 250px;
    text-align: center;
    font-family: Roboto;
    padding: 2px;
    margin: auto;
}


div.car .barre_haut {
    color: #fff !important;
    background-color : #223686 !important;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
}

.contenu_car {
    color: #e4f0f6;
    background-color : none;
    border: 1px solid black;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    padding: 5% 1% 5% 1%;
    color: black;
    font-size: 1.2em;
}

.button_delete {
    color: #010614;
    background-color: #f00;
    border-radius: 5px;
    margin: 10px 5px;
    margin-bottom: 0;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

.button_delete:hover {
    opacity: .8;
    color: #fff;
}

.button_delete:active {
    background-color: #ffe3aa;
}