#nav_in_banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #223686;
  padding: 5px 0;
}

h1#titre{
  line-height: unset;
  height: unset;
  font-size : 3em;
  margin: 0 10px;
  padding-bottom: 0;
  font-family: "Earth";
  color: white;
}
div.userIcon{
  display: flex;
  justify-content: center;
  align-items: center;
}
div.userIcon > svg{
  height: 1.3em;
  width: 1.3em;
}
h1#titre span.little{
  font-size: .7em;
  font-family: "Earth";
}

#nav_in_banner img.logout{
  display: inline-block;
  margin: 0 10px;
  height: 1.5em;
  filter: invert(1);
  cursor: pointer;
  transition: .4s;
}
#nav_in_banner img.logout:hover{
  height: 1.7em;
}
.nav_button{
  display: flex;
  text-decoration:none;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

#svg_container{
  display: inline-block;
  position: relative;
  width: 70%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;
}

.nav_in_banner > ul > .nav {
  display: inline-block;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  padding: 0 20px;
  height: 100%;
  padding-bottom: 2px;
  /* padding-left: 25px; */
  text-align: center;
  font-size: 1.3em;
  transition: color 0.2s;
}

.user_button > .nav{
  color:white;
}

.nav_in_banner > ul > .nav:hover {
  color: #fecc68;
}

span.ripple {
  position: absolute; /* The absolute position we mentioned earlier */
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 300ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}


.nav_in_banner > ul > #logo_in_banner {
  display: inline-block;
  box-sizing: border-box;
  align-items: center;
  margin: auto;
  width: 90px;
  height: 100%;
  padding: 0px;
}

@media screen and (max-width: 600px){
  h1#titre{
    display: none;
  }
}

@media screen and (max-width: 800px){
  div.fullName{
    display: none;
  }
}


.nav_in_banner > ul > .user_button {
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3em;
  color: #0d0;
  margin: 0 10px;
  cursor: pointer;
}

#logo {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  transition: transform .4s;

}

#logo:hover {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  transform: translateX(-5px);
}

.nav_button{
  float: right;
}

.nav_logo{
  display: flex;
  float:left;
  flex-direction: row ;
  align-items: center;
  flex-grow: 1;
}

#VRoum{
  color: white;
  text-shadow: black 0.1em 0.1em 0.2em,-1px 0 black, 0 1px black,
    1px 0 black, 0 -1px black;
  font-size: 1.3em;
}





