.user {
    width: 200px;
    text-align: center;
    font-family: Roboto;
    padding: 2px;
    margin: auto;
}



div.user .barre_haut {
    color: #010614;
    background-color : #fecc68;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center; 
}

.contenu_user {
    color: #e4f0f6;
    background-color : #223686;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    padding: 5% 1% 5% 1%;


}

.passengers {
    margin-bottom: 2%;
}