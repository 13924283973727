.squad {
    width: 250px;
    text-align: left;
    font-family: Roboto;
    padding: 2px;
    font-size: 1.2em;
    margin: 20px auto;
    -webkit-margin-collapse: separate;
}

.title {
    text-align: center;
    padding: 1px;
    font-size: 1.5em;
}

.contenu >.separator {
    border-bottom: 1px solid #fffc;
    margin: 5px;
}

div.squad .barre_haut {
    background-color : #fecc68;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    display: flex;
    flex-direction: column;
    padding: 5px 0;

}

.contenu {
    color: #e4f0f6;
    background-color : #223686;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 9px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 15px 5px;
}

.description {
    vertical-align:middle;
}

.contenu > ul {
    padding: 1px;
}

.button_paps {
    color: #3d3c37;
    background-color: #fecc68;
    border-radius: 10px;
    font-family: Roboto;
    width: 140px;
    border: 2px;
    padding: 10px 5px;
    outline: none;
    font-weight: bold;
    font-size: 12px;
    margin: auto;
    margin-top: 10px;
    cursor: pointer;
}

.button_paps:hover {
    background-color: #ffd787;
    box-shadow: 2px 2px 2px #606062;
    transform: scale(1.05);
}

.button_paps:active {
    background-color: #ffe3aa;
}
