.listsquads {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 5px;
    padding: 5px;
    flex-wrap: wrap;
    }

  div.listsquads div.squad{
    margin: unset;
    margin: auto 15px;
  }