.image_campus {
    /* background-color: black; */
    display: inline-block;
    background-image: url("./../static_image/campus-color.jpg");
    background-size: cover ;
    background-position: top 20% center;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 100vh;
    min-width: 100vw;
}

#image_campus_welcome {
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url("./../static_image/campus-color.jpg");
    background-size: cover ;
    background-position: top 20% center;
    background-repeat: no-repeat;
    min-height: 100vh;
    min-width: 100vw;
}

.white_layer {
    background-color: rgba(255, 255, 255, 0.75);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    min-height:1200px;
    text-align: center;
    justify-content: center;
}

.default_text_black{
    color: #000000;
    font-size : 1.5em;
}


form {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 10px;
}


.wrap_input {
    background-color: #8092db;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 7px;

}


.block_form_vert{
    display: inline-flex;
    margin:auto;
    margin-top: 5px;
    margin-bottom: 5px;
    flex-direction: column;
    width: 400px;

}

#carac_car{
    display: inline-block;
    border: solid 2px rgb(0, 157, 0);
    border-radius: 10px;
    padding: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
}
#carac_car h2.titre{
    border: none;
    text-decoration: underline;
    color: rgb(0, 157, 0);
    font-size: 2em;
    margin: 0;
}

h1.main{
    font-size: 3em;
}
h1.button{
    color: white;
    text-shadow: black 0.2em 0.1em 0.2em,-1px 0 black, 0 1px black,
      1px 0 black, 0 -1px black;
    font-size : 2.1em;
    border: solid 2px #223686;
    border-radius: 10px;
    display: inline-block;
    padding: 10px;
    margin-bottom: 2%;
}

h2 {
    color: #000000;
    font-size : 1.5em;
    border: solid 2px #223686;
    border-radius: 10px;
    display: inline-block;
    padding: 10px;
    margin-bottom: 2%;
}

.AlerteOK {
	background-color: rgba(0, 255, 0, .25);
	display: inline-block;
	padding: 10px;
	border: solid rgba(0, 128, 0, .25);
	border-radius: 10px;
    margin:10px
}

.AlerteOK button {
    background-color: rgba(0, 128, 0, .25);
    padding: 5px 10px 5px 10px;
    margin-right: 10px;
    border-radius: 5px;
}

.AlerteOK button:hover {
    background-color: rgba(0, 128, 0, .55);
}

.user{
    justify-content: center;
    align-items: center;
}

.userList{
    justify-content: center;
    align-items: center;
}

input {
    border: solid #223686 1px;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
}

.input_text {
    border: 1px solid #ced4da;
    padding: 10px 12px 10px 12px;
    position: relative;
    font-size: 16px;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
    height: 2.1876em;
    margin: 8px 5px 10px 5px;
    display: block;
    min-width: 0;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    box-sizing: border-box;
}

.input_text:focus {
    border-radius: 4;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    outline: none;
}

.block_form_hori {
    display: inline;
    margin-bottom: 5px;
    margin-bottom: 5px;
}

.button_form{
    padding: 10px;
    background-color: rgb(63,81,181);
    margin: 5px;
    color: white;
    border-radius: 5px;
    transition: 0.3s;
    font-size: 2em;
    cursor: pointer;
}
.button_form:not(:disabled):hover{
    opacity: .7;
}

.button_form:disabled{
    opacity: .5;
}

#multiline {
    height: 5%;
}

.multilineBlock {
    width: 400px;
}


/*DETAILS*/

.hori_align{
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    padding: 3%;
    gap: 5px;
    width: 80%;
    margin:auto;
}

.responsive_hori_align{
    display: grid;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    padding: 3%;
    gap: 5px;
}

.vert_block{
    display: inline-block;
    margin: auto;
}

.responsive_hori_align > .block_trip {
    grid-row: 1;
}

.responsive_hori_align > .block_car {
    grid-row: 3;
}

.responsive_hori_align > .driver {
    grid-row: 2;
}