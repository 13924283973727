.listtrips {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 5px;
  padding: 5px;
  flex-wrap: wrap;
  }

div.listtrips div.trip{
  margin: unset;
  margin: auto 15px;
}

.addButton{
  width: 250px;
  height: 330px;
  background-color: #223686;
  border-radius: 9px;
}

.addButton:hover{
  background-color: #4b5b9e;
}

.icon{
  transform: scale(3);
  color: white;
}