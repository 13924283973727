@font-face {
  font-family: "Roboto";
  src: url("Roboto-Regular.ttf");
}

@font-face {
  font-family: "Earth";
  src: url("earthorbiter.ttf");
}

*{
  border: 0;
  margin: 0;
  position: static;
  box-sizing: border-box;
  font-family: "Roboto", serif;
}

div#root{
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.fullcenter{
  box-sizing: border-box;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto auto;
  padding: 15px;
  background: #fffc;
  border-radius: 5px;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

